@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base
{
  html
  {
    font-family: 'Raleway',sans-serif;
  }
} 